/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import Context from "../../context/AppContext";
import api from "../../services/users";
import report_api from "../../services/reports";
import Swal from "sweetalert2";
import EventItem from "../Event/EventItem";
import SaleItem from "../Sale/SaleItem";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { es } from "date-fns/locale";
import { ModalForm } from "../ModalForm";
import EventView from "../Event/EventView";
import SaleView from "../Sale/SaleView";

const formatNumber = (number) =>
  new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);

export default function ReportList() {
  const [events, setEvents] = useState([]);
  const [sales, setsales] = useState([]);
  const [informacion, setinformacion] = useState([]);
  const { token } = useContext(Context);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [openModalView, setopenModalView] = useState(false);
  const handleModalCloseView = (flag) => setopenModalView(flag);
  const [editTool, setEditTool] = useState({});
  const [equipos, setequipos] = useState(0);
  const [accesorios, setaccesorios] = useState(0);
  const [total, settotal] = useState(0);

  const [editTool2, setEditTool2] = useState({});
  const [openModalView2, setopenModalView2] = useState(false);
  const handleModalCloseView2 = (flag) => setopenModalView2(flag);

  useEffect(() => {
    get_user();
  }, []);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const get_user = () => {
    api.info_user(token).then((response) => {
      setinformacion(response);
    });
  };

  const view_item = (id) => {
    const itemEdit = events.find((item) => item.id === id);
    setEditTool(itemEdit);
    setopenModalView(true);
  };

  const view_item_sale = (id) => {
    const itemEdit = sales.find((item) => item.id === id);
    setEditTool2(itemEdit);
    setopenModalView2(true);
  };

  const rowEvent = events.map((item) => (
    <EventItem onView={view_item} key={item.id} {...item} />
  ));
  const rowSale = sales.map((item) => (
    <SaleItem onView={view_item_sale} key={item.id} {...item} />
  ));
  const search = () => {
    if (endDate !== "") {
      const data = {
        id_user: informacion?.id,
        date_start: startDate,
        endDate: endDate,
      };
      report_api.reports(token, data).then((response) => {
        const { events, sales } = response.data;

        if (events.length === 0 && sales.length === 0) {
          Swal.fire("Error", "No hay información registrada en estas fechas", "error");
        } else {
          setEvents(events);
          setsales(sales);

          let total_equipos = sales.reduce(
            (a, b) => a + parseFloat(b.monto_equipos),
            0
          );
          setequipos(total_equipos);
          let total_monto_accesorios = sales.reduce(
            (a, b) => a + parseFloat(b.monto_accesorios),
            0
          );
          setaccesorios(total_monto_accesorios);
          let total = total_equipos + total_monto_accesorios;
          settotal(total);
        }
      });
    } else {
      Swal.fire("Error", "Debe de poner el periodo de consulta", "error");
    }
  };
  return (
    <Container className="mt-4">
      <Row className="justify-content-center">
        <Col md={6}>
          <div className="mb-3 row">
            <label
              htmlFor="cuenta_distribuidor"
              className="col-sm-4 col-form-label"
            >
              Cuenta Distribuidor
            </label>
            <div className="col-sm-8">
              <input
                disabled
                type="text"
                placeholder={informacion?.cuenta_SAP ?? ""}
                className="form-control-plaintext"
                id="cuenta_distribuidor"
              />
            </div>
          </div>

          <div className="mb-3 row">
            <label htmlFor="razon_social" className="col-sm-4 col-form-label">
              Razon Social
            </label>
            <div className="col-sm-8">
              <input
                disabled
                type="text"
                placeholder={informacion?.razon_social ?? ""}
                className="form-control-plaintext"
                id="razon_social"
              />
            </div>
          </div>

          <div className="mb-3 row">
            <label htmlFor="ciudad" className="col-sm-4 col-form-label">
              Ciudad
            </label>
            <div className="col-sm-8">
              <input
                disabled
                type="text"
                placeholder={informacion?.ciudad ?? ""}
                className="form-control-plaintext"
                id="ciudad"
              />
            </div>
          </div>

          <div className="mb-3 row">
            <label htmlFor="inputPassword" className="col-sm-4 col-form-label">
              Periodo de Consulta
            </label>
            <div className="col-sm-8">
              <DatePicker
                className="form-control"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={onChange}
                showMonthYearPicker
                withPortal
                locale={es}
                dateFormat="MM/yyyy"
              />
            </div>
          </div>

          <Button className="float-end my-3" variant="success" onClick={search}>
            Buscar
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className="pb-5">
          {events.length > 0 && <h4>EVENTOS</h4>}
          {events.length > 0 && (
            <Table responsive striped bordered hover>
              <thead>
                <tr
                  style={{
                    background: "#ED7D31",
                    color: "white",
                  }}
                >
                  <th>Fecha</th>
                  <th>Tipo de evento</th>
                  <th>Ciudad</th>
                  <th>¿Venta realizadas?</th>
                  <th>Asistentes</th>
                  <th>Monto Vendido</th>
                  <th>Fotos</th>
                </tr>
              </thead>
              <tbody>{rowEvent}</tbody>
            </Table>
          )}
          {sales.length > 0 && (
            <div className="row mt-5">
              <div className="col">
                <h4>VENTAS DEL PERIODO</h4>
              </div>
              <div className="col">
                <h5>Equipos: ${formatNumber(equipos)}</h5>
              </div>
              <div className="col">
                <h5>Accesorios: ${formatNumber(accesorios)}</h5>
              </div>
              <div className="col">
                <h5>Total: ${formatNumber(total)}</h5>
              </div>
            </div>
          )}
          {sales.length > 0 && (
            <Table className="mt-3 pb-5" responsive striped bordered hover>
              <thead>
                <tr
                  style={{
                    background: "#ED7D31",
                    color: "white",
                  }}
                >
                  <th>Fecha</th>
                  <th>Cliente</th>
                  <th>Ciudad</th>
                  <th>Número Equipos</th>
                  <th>Monto Equipos</th>
                  <th>Accesorios</th>
                  <th>Fotos</th>
                </tr>
              </thead>
              <tbody>{rowSale}</tbody>
            </Table>
          )}

          {openModalView && (
            <ModalForm
              show={openModalView}
              title="Ver fotos"
              handleModal={handleModalCloseView}
            >
              <EventView
                editTool={editTool}
                handleModal={handleModalCloseView}
              />
            </ModalForm>
          )}

          {openModalView2 && (
            <ModalForm
              show={openModalView2}
              title="Ver fotos"
              handleModal={handleModalCloseView2}
            >
              <SaleView
                editTool={editTool2}
                handleModal={handleModalCloseView2}
              />
            </ModalForm>
          )}
        </Col>
      </Row>
    </Container>
  );
}
