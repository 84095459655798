const ENDPOINT = process.env.REACT_APP_API_URL;

export async function DatosUsuarioAdd({
    id,
    token
}) {
    return fetch(`${ENDPOINT}/datosUser/${id}`, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token
        },
        method: 'get',
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function DatosUsuarioUpdate(data, token) {
    return fetch(`${ENDPOINT}/datosUserUpdate`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            Authorization: 'Bearer ' + token
        },
        method: 'post',
        body: data
    }).then(res => res.json()).then(response => {
        return response;
    });
}