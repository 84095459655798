import React from "react";
import { Card } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";

const ENDPOINT = process.env.REACT_APP_URL;

export default function EventView(props) {
  const { editTool } = props;
  const { foto } = editTool;
  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      modules={[EffectFade, Navigation, Pagination, Scrollbar, A11y]}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      effect="fade"
    >
      {foto.map((foto, index) => {
        return (
          <SwiperSlide key={index}>
            <Card>
              <Card.Img
                variant="bottom"
                src={ENDPOINT + "/images/ventas/" + foto.ruta}
              />
            </Card>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}
