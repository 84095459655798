import React from 'react'
import Demos from '../components/Demos/DemosList'
import { DemoProvider } from '../context/DemoContext'

export default function DemosPage() {
  return (
    <React.Fragment>
      <DemoProvider>
      <Demos />
      </DemoProvider>
    </React.Fragment>
  )
}