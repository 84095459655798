import React from "react";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { DemoContext } from "../../context/DemoContext";
import { ModalForm } from "../ModalForm";
import DemoForm from "./DemoForm";


export default function DemosList() {
  const { demos, deleteDemo, openModal, setOpenModal, mappingSelect, mappingDealerSelected } =
    React.useContext(DemoContext);
  const [edit, setEdit] = React.useState(false);
  const [properties, setProperties] = React.useState({});

  const handleEdit = (id) => {
    const flag = id !== undefined ? true : false;
    const proper =
      id !== undefined
        ? {
            dealer_id: id,
            tools: mappingSelect(demos.find((x) => x.id === id).products),
            dealerEdit: mappingDealerSelected(demos.find((x) => x.id === id)),
            edit: flag
          }
        : {};
    setEdit(flag);
    setOpenModal(true);
    setProperties(proper);
  };

  
  return (
    <React.Fragment>
      <Container className="mt-4">
        <Row>
          <Col>
            <Button
              className="float-end my-3"
              variant="primary"
              onClick={() => handleEdit()}
            >
              Agregar
            </Button>
          </Col>
        </Row>

        <Row>
          <Col>
            <Table responsive striped bordered hover>
              <thead>
                <tr
                  style={{
                    background: "#ED7D31",
                    color: "white",
                  }}
                >
                  <th>Número distribuidor</th>
                  <th>Nombre comercial</th>
                  <th>Número de equipos demo</th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {demos.length > 0 &&
                  demos.map((item) => (
                    <tr key={item.id}>
                      <td>{item.customer_number}</td>
                      <td>{item.nombre_comercial}</td>
                      <td>{item.products.length}</td>
                      <td>
                        <Button onClick={() => handleEdit(item.id)}>
                          Editar
                        </Button>
                      </td>
                      <td>
                        <Button onClick={() => deleteDemo(item.id)}>
                          Eliminar
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>

            {openModal && (
              <ModalForm
                show={openModal}
                title={edit? "Editar Equipos Demo" : "Selecciona Equipos Demo"}  
                handleModal={setOpenModal}
              >
               <DemoForm {...properties} />

              </ModalForm>
            )}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}
