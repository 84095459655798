/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import Context from "../../context/AppContext";
import api from "../../services/events";
import EventItem from "./EventItem";
import { ModalForm } from "../ModalForm";
import EventForm from "./EventForm";
import EventView from "./EventView";
import Swal from "sweetalert2";
//import EquivalenceEditar from "components/EquivalenceEditar";

export default function EventList() {
  const [events, setEvents] = useState([]);
  const { token } = useContext(Context);

  const [openModal, setOpenModal] = useState(false);
  const [openModalView, setopenModalView] = useState(false);

  const handleModalClose = (flag) => setOpenModal(flag);
  const handleModalCloseView = (flag) => setopenModalView(flag);
  const [editTool, setEditTool] = useState({});

  useEffect(() => {
    get_events();
  }, []);

  const get_events = () => {
    api.events(token).then(({ data }) => {
      setEvents(data);
    });
  };

  const delete_item = (id) => {
    Swal.fire({
      title: "¿Estás seguro de eliminar?",
      showDenyButton: true,
      showCancelButton: true,
      showConfirmButton: false,
      denyButtonText: `Eliminar`,
      cancelButtonText: "Cancelar",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isDenied) {
        api.eventsDestroy(token, id).then((data) => {
          get_events();
        });
      }
    });
  };
  const edit_item = (id) => {
    const itemEdit = events.find((item) => item.id === id);
    /*  setEditTool(itemEdit);            
    setAddPoints(false);    
    setOpenModal(true); */
  };

  const view_item = (id) => {
    const itemEdit = events.find((item) => item.id === id);
    setEditTool(itemEdit);
    setopenModalView(true);
  };

  const rowEquivalence = events.map((item) => (
    <EventItem
      key={item.id}
      {...item}
      onDelete={delete_item}
      onEdit={edit_item}
      onView={view_item}
    />
  ));

  return (
    <Container className="mt-4">
      <Row>
        <Col>
          <Button
            className="float-end my-3"
            variant="primary"
            onClick={() => handleModalClose(true)}
          >
            Agregar
          </Button>
        </Col>
      </Row>

      <Row>
        <Col>
          <Table responsive striped bordered hover>
            <thead>
              <tr
                style={{
                  background: "#ED7D31",
                  color: "white",
                }}
              >
                <th>Fecha</th>
                <th>Tipo de evento</th>
                <th>Ciudad</th>
                <th>¿Venta realizadas?</th>
                <th>Asistentes</th>
                <th>Monto Vendido</th>
                <th>Estatus</th>
                <th>Fotos</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{rowEquivalence}</tbody>
          </Table>
          {openModal && (
            <ModalForm
              show={openModal}
              title="Registrar evento"
              handleModal={handleModalClose}
            >
              <EventForm
                get_events={get_events}
                handleModal={handleModalClose}
              />
            </ModalForm>
          )}

          {openModalView && (
            <ModalForm
              show={openModalView}
              title="Ver fotos"
              handleModal={handleModalCloseView}
            >
              <EventView
                editTool={editTool}
                handleModal={handleModalCloseView}
              />
            </ModalForm>
          )}
        </Col>
      </Row>
    </Container>
  );
}
