import React from "react";
import { Container } from "react-bootstrap";
import Header from "../components/Header";

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <Container fluid>
        <Header />
      </Container>

      <Container>{children}</Container>
    </React.Fragment>
  );
};

export default Layout;
