const ENDPOINT = process.env.REACT_APP_API_URL;

export async function DistribuidoresGet(token) {
    return fetch(`${ENDPOINT}/distribuidoresGet`, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token
        },
        method: 'get',
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function DistribuidoresAdd(data, token) {
    return fetch(`${ENDPOINT}/distribuidoresAdd`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            Authorization: 'Bearer ' + token
        },
        method: 'post',
        body: data
    }).then(res => res.json()).then(response => {
        return response;
    });
}


export async function DistribuidoresUpdate(data, token) {
    return fetch(`${ENDPOINT}/distribuidoresUpdate`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            Authorization: 'Bearer ' + token
        },
        method: 'post',
        body: data
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function DistribuidoresDelete(data, token) {
    return fetch(`${ENDPOINT}/distribuidoresDelete`, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token
        },
        method: 'get',
    }).then(res => res.json()).then(response => {
        return response;
    });
}