import React from 'react'
import ReportList from '../components/Report/ReportList'

export default function Reports() {
  return (
    <React.Fragment>
      <ReportList />
    </React.Fragment>
  )
}
