/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from "react";
import Context from "../../context/AppContext";
import api from "../../services/users";
import ZonesService from "../../services/zones";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useForm, Controller } from "react-hook-form";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays, format } from "date-fns";
import { es } from "date-fns/locale";

const animatedComponents = makeAnimated();

export default function UserForm({ show, get_users }) {
  const { token } = useContext(Context);
  const [states, setStates] = useState([]);
  const [zones, setZones] = useState([]);
  const [registered, setRegistered] = useState(false);


  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {
      zone_id: "",
    },
  });

  const [region, setRegion] = useState("");
  const [zone, setZone] = useState("");

  useEffect(() => {
    const subscription = watch(({ zone_id }) => {
      if (zone_id.hasOwnProperty("value") && zone_id.value > 0) {
        setRegion(zone_id.region);
        setZone(zone_id.zona);
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  useEffect(() => {
    ZonesService(token).then(({ data, states }) => {
      setStates(states);
      setZones(data);
    });
  }, []);

  const onSubmit = (values) => {
    if (values.hasOwnProperty("zone_id")) {
      values.zone_id = values.zone_id.value;
    }
    if (values.fecha_nacimiento) {
      values.fecha_nacimiento = format(values.fecha_nacimiento, "yyyy/MM/dd");
    }
    if (values.fecha_incorporacion) {
      values.fecha_incorporacion = format(
        values.fecha_incorporacion,
        "yyyy/MM/dd"
      );
    }
    api
      .usersStore(token, values)
      .then((response) => {
        console.log("response", response);
        if (response.hasOwnProperty("errors")) {
          console.log(response.errors);
          /*  const vals = Object.keys(response.errors);
                    console.log('values',vals); */
          alert("Favor de revisar el formulario.");
        } else {
          show(false);
          get_users();
        }
      })
      .catch(function (error) {
        console.log("Catch:" + error.message);
      });
  };

  const get_messages = (objeto) => {
    return;
  };

  return (
    <React.Fragment>
      <Container fluid="xxl" className="registro">
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Row className="justify-content-center mt-5">
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="nombre">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nombre(s)"
                  {...register("nombre", {
                    required: "El campo es obligatorio.",
                  })}
                />
                <Form.Text className="text-muted">
                  {errors.nombre?.message}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="apellido_paterno">
                <Form.Label>Apellido Paterno</Form.Label>
                <Form.Control
                  type="text"
                  {...register("apellido_paterno", {
                    required: "El campo es obligatorio.",
                  })}
                />
                <Form.Text className="text-muted">
                  {errors.apellido_paterno?.message}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="apellido_materno">
                <Form.Label>Apellido Materno</Form.Label>
                <Form.Control
                  type="text"
                  {...register("apellido_materno", {
                    required: "El campo es obligatorio.",
                  })}
                />
                <Form.Text className="text-muted">
                  {errors.apellido_materno?.message}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="fecha_nacimiento">
                <Form.Label>Fecha de nacimiento</Form.Label>
                <Controller
                  rules={{ required: "El campo es obligatorio" }}
                  control={control}
                  name="fecha_nacimiento"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Form.Control
                      inputRef={ref}
                      type="text"
                      autoComplete="off"
                      as={ReactDatePicker}
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      maxDate={new Date()}
                      locale={es}
                      placeholderText="Selecciona la fecha"
                      dateFormat="dd-MM-yyyy"
                      error={!!errors.fecha_nacimiento}
                    />
                  )}
                />

                <Form.Text className="text-muted">
                  {errors.fecha_nacimiento?.message}
                </Form.Text>
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  {...register("email", {
                    required: "El campo es obligatorio.",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Debe escribir un correo.",
                    },
                  })}
                />
                <Form.Text className="text-muted">
                  {errors.email?.message}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="telefono">
                <Form.Label>Teléfono</Form.Label>
                <Form.Control
                  type="telefono"
                  {...register("telefono", {
                    required: "El campo es obligatorio.",
                    pattern: {
                      value: /^[0-9]{10}$/i,
                      message: "Debe escribir 10 digítos.",
                    },
                  })}
                />
                <Form.Text className="text-muted">
                  {errors.telefono?.message}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          <Row className="justify-content-left mb-5">
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="password">
                <Form.Label>Contraseña</Form.Label>
                <Form.Control
                  type="text"
                  {...register("password", {
                    required: "El campo es obligatorio.",
                  })}
                />
                <Form.Text className="text-muted">
                  {errors.password?.message}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="sexo">
                <Form.Label>Sexo</Form.Label>

                <Form.Select
                  aria-label="sexo"
                  {...register("sexo", {
                    required: "El campo es obligatorio.",
                  })}
                >
                  <option value="">Selecciona una opción</option>
                  <option value="Masculino">Masculino</option>
                  <option value="Femenino">Femenino</option>
                </Form.Select>
                <Form.Text className="text-muted">
                  {errors.sexo?.message}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="talla">
                <Form.Label>Talla</Form.Label>
                <Form.Select
                  aria-label="talla"
                  {...register("talla", {
                    required: "El campo es obligatorio.",
                  })}
                >
                  <option value="">Selecciona una opción</option>
                  <option value="chica">CH</option>
                  <option value="mediana">M</option>
                  <option value="grande">G</option>
                  <option value="extra grande">XG</option>
                </Form.Select>
                <Form.Text className="text-muted">
                  {errors.talla?.message}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="fecha_incorporacion">
                <Form.Label>Fecha incorporación</Form.Label>
                <Controller
                  rules={{ required: "El campo es obligatorio" }}
                  control={control}
                  name="fecha_incorporacion"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Form.Control
                      inputRef={ref}
                      type="text"
                      autoComplete="off"
                      as={ReactDatePicker}
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      maxDate={new Date()}
                      locale={es}
                      placeholderText="Selecciona la fecha"
                      dateFormat="dd-MM-yyyy"
                      error={!!errors.fecha_incorporacion}
                    />
                  )}
                />

                <Form.Text className="text-muted">
                  {errors.fecha_incorporacion?.message}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xs={12} md={4}></Col>
          </Row>

          <Row>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="zone_id">
                <Form.Label>Estado</Form.Label>
                <Controller
                  rules={{ required: "El campo es obligatorio" }}
                  control={control}
                  name="zone_id"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      options={states}
                      inputRef={ref}
                      as={Select}
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                    />
                  )}
                />
                <Form.Text className="text-muted">
                  {errors.zone_id?.message}
                </Form.Text>
              </Form.Group>
            </Col>

            {zone && (
              <Col xs={12} md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Zona</Form.Label>
                  <Form.Control
                    placeholder="Zona"
                    disabled
                    value={zone}
                    onChange={(e) => setZone(e.target.value)}
                  />
                </Form.Group>
              </Col>
            )}
            {region && (
              <Col xs={12} md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Región</Form.Label>
                  <Form.Control
                    placeholder="Región"
                    disabled
                    value={region}
                    onChange={(e) => setRegion(e.target.value)}
                  />
                </Form.Group>
              </Col>
            )}
          </Row>

          <Row className="mt-5">
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="ciudad">
                <Form.Label>Ciudad</Form.Label>
                <Form.Control
                  type="text"
                  {...register("ciudad", {
                    required: "El campo es obligatorio.",
                  })}
                />
                <Form.Text className="text-muted">
                  {errors.ciudad?.message}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="categoria_distribuidor">
                <Form.Label>Categoria distribuidor</Form.Label>
                <Form.Control
                  type="text"
                  {...register("categoria_distribuidor", {
                    required: "El campo es obligatorio.",
                  })}
                />
                <Form.Text className="text-muted">
                  {errors.categoria_distribuidor?.message}
                </Form.Text>
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="cuenta_SAP">
                <Form.Label>Cuenta SAP</Form.Label>
                <Form.Control
                  type="text"
                  {...register("cuenta_SAP", {
                    required: "El campo es obligatorio.",
                    pattern: {
                      value: /^[0-9]+$/i,
                      message: "Favor de escribir números enteros ",
                    },
                  })}
                />
                <Form.Text className="text-muted">
                  {errors.cuenta_SAP?.message}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3" controlId="responsable">
                <Form.Label>
                  Representante Desarrollo de Mercado responsable
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("responsable", {
                    required: "El campo es obligatorio.",
                  })}
                />
                <Form.Text className="text-muted">
                  {errors.responsable?.message}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3" controlId="responsable">
                <Form.Label>
                  Clase
                </Form.Label>
                <Form.Select
                  aria-label="clase"
                  {...register("clase", {
                    required: "El campo es obligatorio.",
                  })}
                >
                  <option value="">Selecciona una opción</option>
                  <option value="BRONCE">Bronce</option>
                  <option value="PLATA">Plata</option>
                  <option value="ORO">Oro</option>
                </Form.Select>
                <Form.Text className="text-muted">
                  {errors.clase?.message}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          <Button type="submit" className="mb-4 mt-2">
            Enviar
          </Button>
        </Form>
      </Container>
    </React.Fragment>
  );
}
