/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import Context from "../../context/AppContext";
import api from "../../services/users";
import UserItem from "./UserItem";
import { ModalForm } from "../ModalForm";
import UserForm from "./UserForm";
import Swal from "sweetalert2";
//import EquivalenceEditar from "components/EquivalenceEditar";

export default function UserList() {
  const [users, setUsers] = useState([]);
  const { token } = useContext(Context);

  const [openModal, setOpenModal] = useState(false);

  const handleModalClose = (flag) => setOpenModal(flag);
  const [editTool, setEditTool] = useState({});

  useEffect(() => {
    get_users();
  }, []);

  const get_users = () => {
    api.users(token).then(({ data }) => {
      setUsers(data);
    });
  };

  const delete_item = (id) => {
    Swal.fire({
      title: "¿Estás seguro de eliminar?",
      showDenyButton: true,
      showCancelButton: true,
      showConfirmButton: false,
      denyButtonText: `Eliminar`,
      cancelButtonText: "Cancelar",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isDenied) {
        api.usersDestroy(token, id).then((data) => {
          get_users();
        });
      }
    });
  };
  const edit_item = (id) => {
    const itemEdit = users.find((item) => item.id === id);
    /*  setEditTool(itemEdit);            
    setAddPoints(false);    
    setOpenModal(true); */
  };
  const rowEquivalence = users.map((item, valor) => (
    <UserItem
      valor={valor + 1}
      key={item.id}
      {...item}
      onDelete={delete_item}
      onEdit={edit_item}
    />
  ));

  return (
    <Container className="mt-4">
      <Row>
        <Col>
          <Button
            className="float-end my-3"
            variant="primary"
            onClick={() => handleModalClose(true)}
          >
            Agregar
          </Button>
        </Col>
      </Row>

      <Row>
        <Col>
          <Table responsive striped bordered hover>
            <thead>
              <tr
                style={{
                  background: "#ED7D31",
                  color: "white",
                }}
              >
                <th>#</th>
                <th>Nombre</th>
                <th>Correo</th>
                <th>Fecha de nacimiento</th>
                <th>Ciudad</th>
                <th>Role</th>
                <th>Categoria Distribuidor</th>
                <th>Clase</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{rowEquivalence}</tbody>
          </Table>
          {openModal && (
            <ModalForm
              show={openModal}
              title="Usuarios"
              handleModal={handleModalClose}
            >
              <UserForm show={handleModalClose} get_users={get_users} />
            </ModalForm>
          )}
        </Col>
      </Row>
    </Container>
  );
}
