/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from "react";
import Context from "../../context/AppContext";
import api from "../../services/sales";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useForm, Controller } from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { es } from "date-fns/locale";
import { Container, Form, Row, Col, Button, Badge } from "react-bootstrap";
import File from "../Form/File";
import format from "date-fns/format";
import Swal from "sweetalert2";
import Compressor from "compressorjs";
import pic from "../../assets/loader.gif";

const animatedComponents = makeAnimated();

export default function SaleForm({ handleModal, get_sales }) {
  const [products, setProducts] = useState([]);
  const [registered, setRegistered] = useState(false);
  const [files, setfiles] = useState([]);
  const { userData, token } = useContext(Context);
  const [events, setEvents] = useState([]);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  useEffect(() => {
    api.events_picklist(token).then((response) => {
      setEvents(response);
    });
  }, []);

  function compress(file) {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.5,
        success: resolve,
        error: reject,
      });
    });
  }

  const onSubmit = async (data, e) => {
    e.preventDefault();

    const sweet_loader = "<img src=" + pic + " class='img-fluid' />";
    Swal.fire({
      title: "Enviando información",
      html: sweet_loader,
      buttons: false,
      showConfirmButton: false,
      closeOnClickOutside: false,
      allowOutsideClick: false,
    });

    const usuario =
      typeof userData === "string" ? JSON.parse(userData) : userData;
    data.fecha_contacto = format(data.fecha_contacto, "yyyy-MM-dd HH:mm:ss");
    data.fecha_venta = format(data.fecha_venta, "yyyy-MM-dd HH:mm:ss");
    const { value } = data.event_id;

    const formData = new FormData();
    formData.append("fecha_contacto", data.fecha_contacto);
    formData.append("fecha_venta", data.fecha_venta);
    formData.append("cliente", data.cliente);
    formData.append("localidad", data.localidad);
    formData.append("monto_equipos", data.monto_equipos);
    formData.append("numero_equipos", data.numero_equipos);
    formData.append("monto_accesorios", data.monto_accesorios);
    formData.append("event_id", value);
    formData.append("user_id", usuario.id);
    formData.append("size", files.length);

    for (let i = 0; i < files.length; i++) {
      const valor = await compress(files[i].file);
      formData.append(`imagenes_${i}`, valor);
    }

    api.saleStore(token, formData).then((response) => {
      if (response.success) {
        get_sales();
        handleModal(false);
        Swal.fire(
          "Guardado con exito",
          "Registro creado de manera exitosa",
          "success"
        );
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Hubo un error",
        });
      }
    });
  };

  return (
    <React.Fragment>
      <Container fluid="xxl" className="registro">
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Row>
            <Col xs={12} md={8}>
              <Form.Group className="mb-3" controlId="event_id">
                <Form.Label>Selecciona Evento</Form.Label>{" "}
                <Badge bg="secondary">
                  {" "}
                  Solo se muestran eventos de 3 meses atrás.
                </Badge>
                <Controller
                  rules={{ required: "El campo es obligatorio" }}
                  control={control}
                  name="event_id"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      options={events}
                      inputRef={ref}
                      as={Select}
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                    />
                  )}
                />
                <Form.Text className="text-danger">
                  {errors.event_id?.label?.message}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          <Row className="justify-content-center mt-5">
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="fecha_contacto">
                <Form.Label>Fecha de primer contacto</Form.Label>
                <Controller
                  rules={{ required: "El campo es obligatorio" }}
                  control={control}
                  name="fecha_contacto"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Form.Control
                      inputRef={ref}
                      type="text"
                      autoComplete="off"
                      as={ReactDatePicker}
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      maxDate={new Date()}
                      locale={es}
                      placeholderText="Selecciona la fecha"
                      dateFormat="dd-MM-yyyy"
                      error={!!errors.fecha_contacto}
                    />
                  )}
                />

                <Form.Text className="text-muted">
                  {errors.fecha_contacto?.message}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="fecha venta">
                <Form.Label>Fecha de venta</Form.Label>
                <Controller
                  rules={{ required: "El campo es obligatorio" }}
                  control={control}
                  name="fecha_venta"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Form.Control
                      inputRef={ref}
                      type="text"
                      autoComplete="off"
                      as={ReactDatePicker}
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      maxDate={new Date()}
                      locale={es}
                      placeholderText="Selecciona la fecha"
                      dateFormat="dd-MM-yyyy"
                      error={!!errors.fecha_venta}
                    />
                  )}
                />

                <Form.Text className="text-muted">
                  {errors.fecha_venta?.message}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="cliente">
                <Form.Label>Cliente</Form.Label>
                <Form.Control
                  type="text"
                  {...register("cliente", {
                    required: "El campo es obligatorio.",
                  })}
                />
                <Form.Text className="text-muted">
                  {errors.cliente?.message}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          <Row className="justify-content-center mt-5">
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="localidad">
                <Form.Label>Localidad</Form.Label>
                <Form.Control
                  type="text"
                  {...register("localidad", {
                    required: "El campo es obligatorio.",
                  })}
                />
                <Form.Text className="text-muted">
                  {errors.localidad?.message}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="monto_equipos">
                <Form.Label>Monto Venta Equipos</Form.Label>
                <Form.Control
                  type="text"
                  {...register("monto_equipos", {
                    required: "El campo es obligatorio.",
                    pattern: {
                      value: /^[0-9]+$|^[0-9]+\.[0-9]+$/i,
                      message: "Favor de escribir un número entero o decimal.",
                    },
                  })}
                />
                <Form.Text className="text-muted">
                  {errors.monto_equipos?.message}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="numero_equipos">
                <Form.Label>Número de equipos </Form.Label>
                <Form.Control
                  type="text"
                  {...register("numero_equipos", {
                    required: "El campo es obligatorio.",
                    pattern: {
                      value: /^[0-9]+$/i,
                      message: "Favor de escribir números enteros.",
                    },
                  })}
                />
                <Form.Text className="text-muted">
                  {errors.numero_equipos?.message}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={3}>
              <Form.Group className="mb-3" controlId="monto_accesorios">
                <Form.Label> Monto venta accesorios/consumibles</Form.Label>
                <Form.Control
                  type="text"
                  {...register("monto_accesorios", {
                    required: "El campo es obligatorio.",
                    pattern: {
                      value: /^[0-9]+$|^[0-9]+\.[0-9]+$/i,
                      message: "Favor de escribir un número entero o decimal.",
                    },
                  })}
                />
                <Form.Text className="text-muted">
                  {errors.monto_accesorios?.message}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <File
                name="files"
                title="Foto o PDF"
                files={files}
                setfiles={setfiles}
                limit={null}
                fileTypes={["application/pdf", "image/png", "image/jpeg"]}
              />
            </Col>
          </Row>

          <Button type="submit" className="mb-4 mt-2">
            Enviar
          </Button>
        </Form>
      </Container>
    </React.Fragment>
  );
}
