import React, { useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useForm, Controller } from "react-hook-form";
import api from "../../services/demos";
import Context from "../../context/AppContext";
import { DemoContext } from "../../context/DemoContext";

const animatedComponents = makeAnimated();

export default function DemoForm({ dealer_id, tools, dealerEdit }) {
  const [dealers, setDealers] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const { token } = React.useContext(Context);
  const { saveDemo, updateDemo } = React.useContext(DemoContext);
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  let defaultValue = null;

 

  useEffect(() => {
    if (dealer_id !== undefined) {
      setDealers([dealerEdit]);
      setValue("picklist_dealers", dealerEdit);
    } else {
      api.demosDealers(token).then((data) => {
        setDealers(data);   
      });
    }
    api.demosProducts(token).then((data) => {
      setProducts(data);
      setValue("picklist_products", tools);
    });
  }, [token, dealer_id]);

  const onSubmit = (data, e) => {
    e.preventDefault();
    if (dealer_id !== undefined) {    
      updateDemo(data);
    } else {
      saveDemo(data);
    }
  };

  return (
    <React.Fragment>
      <Container fluid="xxl" className="registro">
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Row>
            <Col xs={12}>
              <Form.Group className="mb-3" controlId="dealers">
                <Form.Label>Selecciona un distribuidor</Form.Label>
                <Controller
                  rules={{ required: "El campo es obligatorio" }}
                  control={control}
                  name="picklist_dealers"
                 
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      ref={ref}
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      options={dealers}
                      inputRef={ref}
                      as={Select}
                      onChange={onChange}
                      isDisabled={dealer_id !== undefined}
                      value={
                        dealer_id !== undefined ? dealers[0] : value
                      }
                    />
                  )}
                />
                <Form.Text className="text-muted">
                  {errors.picklist_dealers?.message}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Group className="mb-3" controlId="products">
                <Form.Label>Selecciona los equipos Demo</Form.Label>
                <Controller
                rules={{ required: "El campo es obligatorio" }}
                  control={control}
                  name="picklist_products"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      closeMenuOnSelect={false}
                      isMulti
                      components={animatedComponents}
                      options={products}
                      inputRef={ref}
                      as={Select}
                      onChange={onChange}
                      onBlur={onBlur}
                      defaultValue={dealer_id !== undefined ? tools : null}
                    />
                  )}
                />
                <Form.Text className="text-muted">
                  {errors.picklist_products?.message}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button type="submit" className="mb-4 mt-2">
                Enviar
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </React.Fragment>
  );
}
