const ENDPOINT = process.env.REACT_APP_API_URL

 export default function login ({ username, password }) {
  return fetch(`${ENDPOINT}/auth/login`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest"
    },
    body: JSON.stringify({ 'email':username, password, 'device_name':'computer'})
  }).then(res => { 
    if (!res.ok) throw new Error('Response is NOT ok')
     return res.json() }).then(({token, id_user, user_data, rol}) => {
    return {token, id_user, user_data, rol}
  })
}