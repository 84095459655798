import React, { useState, useEffect, useContext } from 'react'
import Select from 'react-select';
import useDatos from '../hooks/useDatos';
import Context from "../context/AppContext";
import Swal from "sweetalert2";
import avatar from "../assets/images/Usuario.png";

const genero = [
  { value: "Masculino", label: "Masculino" },
  { value: "Femenino", label: "Femenino" },
];

const tallas = [
  { value: "chica", label: "CH" },
  { value: "mediana", label: "M" },
  { value: "grande", label: "G" },
  { value: "extra grande", label: "XG" },
];

const escolar = [
  { value: "Básica", label: "Básica" },
  { value: "Licenciatura no concluida", label: "Licenciatura no concluida" },
  { value: "Carrera Técnica", label: "Carrera Técnica" },
  { value: "Licenciatura", label: "Licenciatura" },
];

// const clases = [
//   { value: "BRONCE", label: "BRONCE" },
//   { value: "PLATA", label: "PLATA" },
//   { value: "ORO", label: "ORO" },
// ];

export default function Users() {
  const { loading, error, message, success,
    Nombre, setNombre, ApellidoP, setApellidoP, ApellidoM, setApellidoM, Email, Telefono, setTelefono, Fecha, setFecha,
    Sexo, setSexo, Talla, setTalla, Escolaridad, setEscolaridad, Clase, setClase, Foto,
    DatosUsuarioAddService, UsuariosUpdateService } = useDatos();
  const { token, IDUser } = useContext(Context);

  const ENDPOINT = process.env.REACT_APP_IMG;

  const [SelectedSexo, setSelectedSexo] = useState([]);
  const [SelectedTalla, setSelectedTalla] = useState([]);
  const [SelectedEscolar, setSelectedEscolar] = useState([]);
  const [SelectedClase, setSelectedClase] = useState([]);
  const [selectedFile, setselectedFile] = useState('');
  const [isFilePicked, setisFilePicked] = useState(false);

  const changeHandler = (event) => {
    setselectedFile(event.target.files[0]);
    setisFilePicked(true);
  };

  const createUser = () => {
    const data = new FormData();
    data.append("id", IDUser);
    data.append("nombre", Nombre);
    data.append("apellido_paterno", ApellidoP);
    data.append("apellido_materno", ApellidoM);
    data.append("fecha_nacimiento", Fecha);
    data.append("talla", Talla);
    data.append("sexo", Sexo);
    data.append("telefono", Telefono);
    data.append("escolaridad", Escolaridad);
    data.append("clase", 'BRONCE');
    data.append("foto", selectedFile);

    UsuariosUpdateService(data, token);
  }

  useEffect(() => {
    DatosUsuarioAddService({ id: IDUser, token })
  }, [DatosUsuarioAddService])

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
    }
  }, [message, error])

  useEffect(() => {
    if (success) {
      Swal.fire({
        icon: "success",
        title: "Exito",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
    }
  }, [message, success])

  return <>
    <div className='container'>
      <div className='row'>
        <div className='col-md-6 mx-auto'>
          {
            Foto == null
            ?
            <img src={avatar} className="img-fluid mx-auto d-block" />
            :
            <img src={ENDPOINT+'images/fotos/'+Foto} style={{width:150}} className="img-fluid mx-auto d-block rounded-circle" />
          }
          
          <label>Foto</label>
          <input type={'file'} className='form-control' onChange={changeHandler} />
        </div>
      </div>
      <br />
      <div className='row'>
        <div className='col-md-4 mb-3'>
          <label>Nombre:</label>
          <input type={'text'} className='form-control' value={Nombre} onChange={e => setNombre(e.target.value)} />
        </div>
        <div className='col-md-4 mb-3'>
          <label>Apellido Paterno:</label>
          <input type={'text'} className='form-control' value={ApellidoP} onChange={e => setApellidoP(e.target.value)} />
        </div>
        <div className='col-md-4 mb-3'>
          <label>Apellido Materno:</label>
          <input type={'text'} className='form-control' value={ApellidoM} onChange={e => setApellidoM(e.target.value)} />
        </div>
        <div className='col-md-4 mb-3'>
          <label>Email:</label>
          <input type={'text'} className='form-control' value={Email} disabled={true} />
        </div>
        <div className='col-md-4 mb-3'>
          <label>Teléfono:</label>
          <input type={'text'} className='form-control' value={Telefono} onChange={e => setTelefono(e.target.value)} />
        </div>
        <div className='col-md-4 mb-3'>
          <label>Fecha de Nacimiento:</label>
          <input type={'date'} className='form-control' value={Fecha} onChange={e => setFecha(e.target.value)} />
        </div>
        <div className='col-md-4 mb-3'>
          <label>Sexo:</label>
          <Select
            value={SelectedSexo}
            onChange={e => {
              setSexo(e.value)
              setSelectedSexo(e)
            }}
            options={genero}
            placeholder={Sexo ? Sexo : "Seleccionar Sexo"}
          />
        </div>
        <div className='col-md-4 mb-3'>
          <label>Talla:</label>
          <Select
            value={SelectedTalla}
            onChange={e => {
              setTalla(e.value)
              setSelectedTalla(e)
            }}
            options={tallas}
            placeholder={Talla ? Talla : "Seleccionar Talla"}
          />
        </div>
        <div className='col-md-4 mb-3'>
          <label>Escolaridad:</label>
          <Select
            value={SelectedEscolar}
            onChange={e => {
              setEscolaridad(e.value)
              setSelectedEscolar(e)
            }}
            options={escolar}
            placeholder={Escolaridad ? Escolaridad : "Seleccionar tu Escolaridad"}
          />
        </div>
        {/* <div className='col-md-4 mb-3'>
          <label>Clase:</label>
          <Select
            value={SelectedClase}
            onChange={e => {
              setClase(e.value)
              setSelectedClase(e)
            }}
            options={clases}
            placeholder={Clase ? Clase : "Seleccionar Clase"}
          />
        </div> */}
      </div>
      <br />
      <div className='row'>
        <div className='col-md-12 text-end'>
          <button className='btn btn-primary' onClick={(() => createUser())}>Actualizar</button>
        </div>
      </div>
    </div>
  </>
}
