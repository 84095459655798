/* eslint-disable eqeqeq */
/* eslint-disable import/no-anonymous-default-export */
const ENDPOINT = process.env.REACT_APP_API_URL;

async function reports(token, data) {
  return await fetch(`${ENDPOINT}/reports`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((res) => {
    if (res.status == 422) return res.json();
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

async function reports_descargar(token) {
  return await fetch(`${ENDPOINT}/reports-get`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      headers: { Accept: "multipart/form-data" },
    },
  }).then((res) => {
    return res.blob();
  });
}

export default {
  reports,
  reports_descargar,
};
