import React from "react";
import { Row, Col, Button } from "react-bootstrap";
const formatNumber = (number) => new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number)

export default function SaleItem({
  id,
  fecha_venta,
  localidad,
  numero_equipos,
  monto_equipos,
  monto_accesorios,
  cliente,
  onDelete,
  onView,
}) {
  return (
    <tr key={id}>
      <td>
        {" "}
        <span> {fecha_venta} </span>
      </td>
      <td>
        <span>{cliente}</span>
      </td>
      <td>
        <span>{localidad}</span>
      </td>
      <td>
        <span>{numero_equipos}</span>
      </td>
      <td>
        <span>${formatNumber(monto_equipos)}</span>
      </td>
      <td>
        <span>${formatNumber(monto_accesorios)}</span>
      </td>
      {onView && (
        <td>
          <Button variant="warning" onClick={() => onView(id)}>
            Ver
          </Button>
        </td>
      )}
      {onDelete && (
        <td>
          <Button variant="danger" onClick={() => onDelete(id)}>
            {" "}
            Eliminar{" "}
          </Button>
        </td>
      )}
    </tr>
  );
}
