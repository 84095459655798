/* eslint-disable import/no-anonymous-default-export */
const ENDPOINT = process.env.REACT_APP_API_URL;

 async function demos(token) {
  return await fetch(`${ENDPOINT}/demos`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

async function demosStore(token, data) {
  return await fetch(`${ENDPOINT}/demos`, {
    method: "POST",
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token,
    },
    body: data,
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}
//
async function demosUpdate(token, data, id) {
  return await fetch(`${ENDPOINT}/demos/${id}`, {
    method: "PUT",
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: data,
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

async function DemosDestroy(token, id) {
    return await fetch(`${ENDPOINT}/demos/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    }).then((res) => {
      if (!res.ok) throw new Error("Response is NOT ok");
      return res.json();
    });
  }

  async function demosDealers(token) {
    return await fetch(`${ENDPOINT}/dealers`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    }).then((res) => {
      if (!res.ok) throw new Error("Response is NOT ok");
      return res.json();
    });
  }

  async function demosDealer(token,id) {
    return await fetch(`${ENDPOINT}/dealer/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    }).then((res) => {
      if (!res.ok) throw new Error("Response is NOT ok");
      return res.json();
    });
  }

  async function demosProducts(token) {
    return await fetch(`${ENDPOINT}/products`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    }).then((res) => {
      if (!res.ok) throw new Error("Response is NOT ok");
      return res.json();
    });
  }

export default {demos, DemosDestroy, demosDealers,demosProducts,demosStore, demosDealer, demosUpdate};