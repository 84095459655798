import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import csrfService from "../services/csrf";
import "../styles/Login.css";
import useUser from "../hooks/useUser";
import pic from "../assets/images/logo_especialista_marca.png";
import Swal from "sweetalert2";

export default function Login({ onLogin }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  //const [, navigate] = useLocation();
  let navigate = useNavigate();
  const { isLoginLoading, hasLoginError, login, isLogged } = useUser();

  useEffect(() => {
    if (isLogged) {
      navigate("/dashboard");
      onLogin && onLogin();
    }
  }, [isLogged, navigate, onLogin]);

  const handleSubmit = (e) => {
    e.preventDefault();
    csrfService().then(() => {
      login({ username, password });
    });
  };

  useEffect(() => {
    if (hasLoginError) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Correo o contraseña incorrectos",
      });
    }
    return () => {};
  }, [hasLoginError]);

  return (
    <React.Fragment>
      <div className="login__background">
        <Container>
          <div className="d-flex justify-content-center align-items-center vh-100">
            <form className="login" onSubmit={handleSubmit}>
              <h3 className="login__title">Inicio de sesión</h3>
              {/* <ul className="login__links">
              <li><Link to="/login"> Inicio de sesión</Link> </li>
              <li><Link to="/register"> Registro </Link> </li>
            </ul> */}

              <input
                className="login__input"
                placeholder="Correo eléctronico"
                onChange={(e) => setUsername(e.target.value)}
                value={username}
              />

              <input
                className="login__input"
                type="password"
                placeholder="Contraseña"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
              <button className="btn-primary d-block mx-auto my-3">
                Iniciar sesión
              </button>
              {/*hasLoginError && (
                <div className="alert alert-danger" role="alert">
                  Credentials are invalid.
                </div>
              )*/}
            </form>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}
