/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from "react";
import Context from "../../context/AppContext";
import api from "../../services/events";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useForm, Controller } from "react-hook-form";
import {
  Container,
  Form,
  Row,
  Col,
  Button,
  FloatingLabel,
} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { es } from "date-fns/locale";
import File from "../Form/File";
import format from "date-fns/format";
import Swal from "sweetalert2";
import Compressor from "compressorjs";
import pic from "../../assets/loader.gif";

const animatedComponents = makeAnimated();

export default function EventForm({ handleModal, get_events, editTool }) {
  const { userData, token } = useContext(Context);
  const [rechazado, setrechazado] = useState(false);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm();

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (value.status === "Rechazado") {
        setrechazado(true);
      } else {
        setrechazado(false);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = (data, e) => {
    e.preventDefault();

    const sweet_loader = "<img src=" + pic + " class='img-fluid' />";
    Swal.fire({
      title: "Enviando información",
      html: sweet_loader,
      buttons: false,
      showConfirmButton: false,
      closeOnClickOutside: false,
      allowOutsideClick: false,
    });

    api
      .eventsUpdate(token, editTool.id, {
        comment: data.comentario,
        status: data.status,
      })
      .then((response) => {
        if (response.success) {
          get_events();
          handleModal(false);
          Swal.fire(
            "Guardado con exito",
            "Registro creado de manera exitosa",
            "success"
          );
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Hubo un error",
          });
        }
      });
  };

  return (
    <React.Fragment>
      <Container fluid="xxl" className="registro">
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Row>
            <h5>{editTool.tipo}</h5>
            <p>{editTool.localidad}</p>
            <p>{editTool.fecha}</p>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12}>
              <Form.Group className="mb-3" controlId="status">
                <Form.Label>Autorizar evento</Form.Label>

                <Form.Select
                  aria-label="status"
                  {...register("status", {
                    required: "El campo es obligatorio.",
                  })}
                >
                  <option value="">Selecciona una opción</option>
                  <option value="Pendiente">Pendiente </option>
                  <option value="Autorizado">Autorizar</option>
                  <option value="Rechazado">Rechazar</option>
                </Form.Select>
                <Form.Text className="text-muted">
                  {errors.status?.message}
                </Form.Text>
              </Form.Group>
            </Col>
            {rechazado && (
              <Col xs={12} md={12}>
                <Form.Group className="mb-3" controlId="comentario">
                  <FloatingLabel controlId="comentario" label={"Comentario"}>
                    <Form.Control
                      as="textarea"
                      placeholder="Escribe un breve comentario"
                      style={{ height: "100px" }}
                      {...register("comentario", {
                        required: "El campo es obligatorio.",
                      })}
                    />
                  </FloatingLabel>
                  <Form.Text className="text-muted">
                    {errors.comentario?.message}
                  </Form.Text>
                </Form.Group>
              </Col>
            )}
          </Row>

          <Row className="d-flex justify-content-end">
            <Button type="submit" className="mb-4 mt-2">
              Actualizar
            </Button>
          </Row>
        </Form>
      </Container>
    </React.Fragment>
  );
}
