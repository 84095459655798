import React from 'react'
import UserList from '../components/User/UserList'

export default function Users() {
  return (
    <React.Fragment>
      <UserList />
    </React.Fragment>
  )
}
