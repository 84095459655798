import { useCallback, useState } from 'react';
import { DatosUsuarioAdd, DatosUsuarioUpdate } from '../services/datos';

export default function useUsers() {
    const [Nombre, setNombre] = useState('');
    const [ApellidoP, setApellidoP] = useState('');
    const [ApellidoM, setApellidoM] = useState('');
    const [Telefono, setTelefono] = useState('');
    const [Email, setEmail] = useState('');
    const [Fecha, setFecha] = useState('');
    const [Talla, setTalla] = useState('');
    const [Sexo, setSexo] = useState('');
    const [Escolaridad, setEscolaridad] = useState('');
    const [Foto, setFoto] = useState('');
    const [Clase, setClase] = useState('');
    const [State, setState] = useState({ loading: false, error: false, message: '', success: false });
    /*
     * Agregar usuario
     */
    const DatosUsuarioAddService = useCallback(({
        id,
        token
    }) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        DatosUsuarioAdd({
            id,
            token
        }).then(response => {
            let errores = response.errors;
            if (errores !== undefined) {
                let id = errores['id'];
                if (id != null) { setState({ loading: false, error: true, message: id[0], success: false }) }
            } else {
                if (response.guardado === true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Datos de Usuario encontrados con exito',
                        success: true
                    });
                    setNombre(response.nombre);
                    setApellidoP(response.apellido_paterno);
                    setApellidoM(response.apellido_materno);
                    setTelefono(response.telefono);
                    setEmail(response.email);
                    setFecha(response.fecha_nacimiento);
                    setTalla(response.talla);
                    setSexo(response.sexo);
                    setEscolaridad(response.escolaridad);
                    setFoto(response.foto);
                    setClase(response.clase);
                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al encontrar datos de usuario',
                        success: false
                    })
                }
            }
        })
    }, [])

    /*
     * Actualizar usuario
     */
    const UsuariosUpdateService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        DatosUsuarioUpdate(
            data, token
        ).then(response => {
            let errores = response.errors;
            if (errores !== undefined) {
                let nombre = errores['nombre'];
                let apellido_paterno = errores['apellido_paterno'];
                let apellido_materno = errores['apellido_materno'];
                let telefono = errores['telefono'];
                let fecha_nacimiento = errores['fecha_nacimiento'];
                let talla = errores['talla'];
                let sexo = errores['sexo'];
                let escolaridad = errores['escolaridad'];
                let clase = errores['clase'];
                if (nombre != null) { setState({ loading: false, error: true, message: nombre[0], success: false }) }
                if (apellido_paterno != null) { setState({ loading: false, error: true, message: apellido_paterno[0], success: false }) }
                if (apellido_materno != null) { setState({ loading: false, error: true, message: apellido_materno[0], success: false }) }
                if (telefono != null) { setState({ loading: false, error: true, message: telefono[0], success: false }) }
                if (fecha_nacimiento != null) { setState({ loading: false, error: true, message: fecha_nacimiento[0], success: false }) }
                if (talla != null) { setState({ loading: false, error: true, message: talla[0], success: false }) }
                if (sexo != null) { setState({ loading: false, error: true, message: sexo[0], success: false }) }
                if (escolaridad != null) { setState({ loading: false, error: true, message: nombre[0], escolaridad: false }) }
                if (clase != null) { setState({ loading: false, error: true, message: clase[0], success: false }) }
            } else {
                if (response.guardado === true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Usuario actualizado con exito',
                        success: true
                    })
                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al actualizar usuario',
                        success: false
                    })
                }
            }
        })
    }, [])
   

    return {
        loading: State.loading,
        error: State.error,
        message: State.message,
        success: State.success,
        Nombre,
        setNombre,
        ApellidoP,
        setApellidoP,
        ApellidoM,
        setApellidoM,
        Telefono,
        setTelefono,
        Email,
        Fecha,
        setFecha,
        Talla,
        setTalla,
        Sexo,
        setSexo,
        Escolaridad,
        setEscolaridad,
        Foto,
        Clase,
        setClase,
        DatosUsuarioAddService,
        UsuariosUpdateService,
    }



}