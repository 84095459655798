import { useCallback, useState } from 'react';
import { DistribuidoresGet, DistribuidoresAdd, DistribuidoresUpdate, DistribuidoresDelete } from '../services/distribuidores';

export default function useUsers() {
    const [Distribuidores, setDistribuidores] = useState([]);
    const [State, setState] = useState({ loading: false, error: false, message: '', success: false });

    const DistribuidoresGetService = useCallback((token) => {
        DistribuidoresGet(token).then(response => {
            setDistribuidores(response)
        })
    }, []);
    /*
    * Agregar usuario
    */
    const DistribuidoresAddService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        DistribuidoresAdd(
            data, token
        ).then(response => {
            let errores = response.errors;
            if (errores !== undefined) {
                let nombre = errores['nombre'];
                let numero = errores['numero'];
                if (nombre != null) { setState({ loading: false, error: true, message: nombre[0], success: false }) }
                if (numero != null) { setState({ loading: false, error: true, message: numero[0], success: false }) }
            } else {
                if (response.guardado === true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Distribuidor actualizado con exito',
                        success: true
                    })
                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al actualizar distribuidor',
                        success: false
                    })
                }
            }
        })
    }, [])
    /*
     * Actualizar usuario
     */
    const DistribuidoresUpdateService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        DistribuidoresUpdate(
            data, token
        ).then(response => {
            let errores = response.errors;
            if (errores !== undefined) {
                let nombre = errores['nombre'];
                let numero = errores['numero'];
                if (nombre != null) { setState({ loading: false, error: true, message: nombre[0], success: false }) }
                if (numero != null) { setState({ loading: false, error: true, message: numero[0], success: false }) }
            } else {
                if (response.guardado === true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Distribuidor actualizado con exito',
                        success: true
                    })
                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al actualizar distribuidor',
                        success: false
                    })
                }
            }
        })
    }, [])
    /*
    * Eliminar usuario
    */
    const DistribuidoresDeleteService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        DistribuidoresDelete(
            data, token
        ).then(response => {
            let errores = response.errors;
            if (errores !== undefined) {
                let nombre = errores['nombre'];
                let numero = errores['numero'];
                if (nombre != null) { setState({ loading: false, error: true, message: nombre[0], success: false }) }
                if (numero != null) { setState({ loading: false, error: true, message: numero[0], success: false }) }
            } else {
                if (response.guardado === true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Distribuidor eliminado con exito',
                        success: true
                    })
                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al eliminar distribuidor',
                        success: false
                    })
                }
            }
        })
    }, [])


    return {
        loading: State.loading,
        error: State.error,
        message: State.message,
        success: State.success,
        Distribuidores,
        DistribuidoresGetService,
        DistribuidoresAddService,
        DistribuidoresUpdateService,
        DistribuidoresDeleteService
    }
}