import React, { useState, useEffect, useContext } from 'react';
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import useDilers from '../hooks/useDilers';
import Context from "../context/AppContext";
import { ModalDistribuidores } from './Modals/ModalDistribuidores';
import { ModalDelete } from './Modals/ModalDistribuidoresDel';

export default function Users() {
    const { loading, error, message, success, Distribuidores, DistribuidoresGetService } = useDilers();
    const { token, IDUser } = useContext(Context);
    const [Modal, setModal] = useState(false);
    const [ModalDel, setModalDel] = useState(false);
    const handleModalClose = (flag) => setModal(flag);
    const handleModalDelClose = (flag) => setModalDel(flag);    
    const [Update, setUpdate] = useState(false);
    const [Id, setId] = useState('');
    const [Nombre, setNombre] = useState('');
    const [Numero, setNumero] = useState('');

    let i = 1;
    useEffect(() => {
        DistribuidoresGetService(token)
    }, [DistribuidoresGetService])

    const toggle = () => {
        setModal(!Modal);
        setUpdate(false);
        setId('');
        setNombre('');
        setNumero('');
    }

    const toggleDel = () => {
        setModalDel(!ModalDel);
        setUpdate(false);
        setId('');
        setNombre('');
        setNumero('');
    }


    return <>
        <ModalDistribuidores
            show={Modal}
            title="Distribuidores"
            handleModal={handleModalClose}
            Nombre={Nombre}
            Numero={Numero}
            Id={Id}
            token={token}
            Update={Update}
            setNombre={setNombre}
            setNumero={setNumero}
            DistribuidoresGetService={DistribuidoresGetService}
        />

        <ModalDelete
            show={ModalDel}
            title="Eliminar Distribuidor"
            handleModalDelClose={handleModalDelClose}
            Nombre={Nombre}
            Numero={Numero}
            Id={Id}
            token={token}
            DistribuidoresGetService={DistribuidoresGetService}
        />
        <Container className="mt-4">
            <Row>
                <Col>
                    <Button
                        className="float-end my-3"
                        variant="primary"
                        onClick={toggle}
                    >
                        Agregar
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table responsive striped bordered hover>
                        <thead>
                            <tr
                                style={{
                                    background: "#ED7D31",
                                    color: "white",
                                }}
                            >
                                <th>#</th>
                                <th>Numero Sap</th>
                                <th>Nombre Comercial</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Distribuidores.map(({ id, customer_number, nombre_comercial }) => {
                                    return <tr className="text-black" key={id}>
                                        <td>{i++}</td>
                                        <td className='text-uppercase fw-bold '>{nombre_comercial}</td>
                                        <td className='text-uppercase fw-bold text-danger'>{customer_number}</td>
                                        <td className="text-center">
                                            <button className="btn btn-warning btn-sm"
                                                onClick={() => {
                                                    setId(id);
                                                    setNombre(nombre_comercial);
                                                    setNumero(customer_number);
                                                    setUpdate(true);
                                                    setModal(true);
                                                }}>
                                                Editar
                                            </button>
                                        </td>
                                        <td className="text-center">
                                            <button className="btn btn-danger btn-sm"
                                                onClick={() => {
                                                    setId(id);
                                                    setNombre(nombre_comercial);
                                                    setNumero(customer_number);
                                                    setModalDel(true);
                                                }}>Eliminar
                                            </button>
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    </>
}