import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppContextProvider } from "../context/AppContext";
import Layout from "../containers/Layout";
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import RerportesExcel from "../pages/RerportesExcel";
import Sales from "../pages/Sales";
import Events from "../pages/Events";
import EventsA from "../pages/EventsA";
import Reports from "../pages/Reports";
import Users from "../pages/Users";
import NotFound from "../pages/NotFound";
import Datos from "../pages/MisDatos";
import DemosPage from "../pages/Demos";
import Distribuidores from "../pages/Distribuidores";

function App() {
  return (
    <AppContextProvider>
      <BrowserRouter >
        <Layout>
          <Routes>
            <Route element={<Login />} path="/" />
            <Route element={<Dashboard />} path="/dashboard" />
            <Route element={<Sales />} path="/sales" />
            <Route element={<Events />} path="/events" />
            <Route element={<EventsA />} path="/events-autorize" />
            <Route element={<Reports />} path="/reports" />
            <Route element={<RerportesExcel />} path="/reports-descargar" />
            <Route element={<Users />} path="/users" />
            <Route element={<Datos />} path="/mis-datos" />
            <Route element={<Distribuidores />} path="/dilers" />
            <Route element={<DemosPage />} path="/demos" />
            <Route element={<NotFound />} path="/:rest" />
          </Routes>
        </Layout>
      </BrowserRouter>
    </AppContextProvider>
  );
}

export default App;
