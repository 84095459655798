import React from "react";
import { Row, Col, Button } from "react-bootstrap";

export default function UserItem({
  valor,
  id,
  nombre,
  email,
  fecha_nacimiento,
  ciudad,
  role,
  categoria_distribuidor,
  clase,
  onDelete,
  onEdit,
}) {
  return (
    <tr key={id}>
      <td>
        <span> {valor} </span>
      </td>
      <td>
        <span>{nombre}</span>
      </td>
      <td>
        <span>{email}</span>
      </td>
      <td>
        <span>{fecha_nacimiento}</span>
      </td>
      <td>
        <span>{ciudad}</span>
      </td>
      <td>
        <span>{role}</span>
      </td>
      <td>
        <span>{categoria_distribuidor}</span>
      </td>
      <td>
        <span className={
          clase == 'BRONCE' &&
          "badge rounded-pill bronce"
          || clase == 'PLATA' &&
          "badge rounded-pill plata"
          || clase == 'ORO' &&
          "badge rounded-pill oro"
        } >{clase}</span>
      </td>
      <td>
        <Button variant="danger" onClick={() => onDelete(id)}>
          {" "}
          Eliminar{" "}
        </Button>
      </td>
    </tr>
  );
}
