import React, { useState } from 'react'

const Context = React.createContext({});

export function AppContextProvider({ children }) {
  const [token, setToken] = useState(
    () => window.sessionStorage.getItem('token')
  )
  const [userData, setUserData] = useState(
    () => window.sessionStorage.getItem('user_data')
  )

  const [IDUser, setIDUser] = useState(
    () => window.sessionStorage.getItem('id_user')
  )

  return <Context.Provider value={{
    token,
    setToken,
    IDUser,
    setIDUser,
    userData,
    setUserData
  }}>
    {children}
  </Context.Provider>
}


export default Context;