/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import Context from "../../context/AppContext";
import api from "../../services/sales";
import SaleItem from "./SaleItem";
import { ModalForm } from "../ModalForm";
import SaleForm from "./SaleForm";
import SaleView from "./SaleView";
import Swal from "sweetalert2";

export default function SaleList() {
  const [sales, setSales] = useState([]);
  const { token } = useContext(Context);

  const [openModal, setOpenModal] = useState(false);
  const [openModalView, setopenModalView] = useState(false);
  const [editTool, setEditTool] = useState({});

  const handleModalClose = (flag) => setOpenModal(flag);
  const handleModalCloseView = (flag) => setopenModalView(flag);

  useEffect(() => {
    get_sales();
  }, []);

  const get_sales = () => {
    api.sales(token).then(({ data }) => {
      setSales(data);
    });
  };

  const delete_item = (id) => {
    Swal.fire({
      title: "¿Estás seguro de eliminar?",
      showDenyButton: true,
      showCancelButton: true,
      showConfirmButton: false,
      denyButtonText: `Eliminar`,
      cancelButtonText: "Cancelar",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isDenied) {
        api.saleDestroy(token, id).then((data) => {
          get_sales();
        });
      }
    });
  };

  const edit_item = (id) => {
    const itemEdit = sales.find((item) => item.id == id);
    /*  setEditTool(itemEdit);            
    setAddPoints(false);    
    setOpenModal(true); */
  };

  const view_item = (id) => {
    const itemEdit = sales.find((item) => item.id === id);
    setEditTool(itemEdit);
    setopenModalView(true);
  };

  const rowEquivalence = sales.map((item) => (
    <SaleItem
      key={item.id}
      {...item}
      onDelete={delete_item}
      onEdit={edit_item}
      onView={view_item}
    />
  ));

  return (
    <Container className="mt-4">
      <Row>
        <Col>
          <Button
            className="float-end my-3"
            variant="primary"
            onClick={() => handleModalClose(true)}
          >
            Agregar
          </Button>
        </Col>
      </Row>

      <Row>
        <Col>
          <Table responsive striped bordered hover>
            <thead>
              <tr
                style={{
                  background: "#ED7D31",
                  color: "white",
                }}
              >
                <th>Fecha</th>
                <th>Cliente</th>
                <th>Ciudad</th>
                <th>Número Equipos</th>
                <th>Monto Equipos</th>
                <th>Accesorios</th>
                <th>Fotos</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{rowEquivalence}</tbody>
          </Table>
          {openModal && (
            <ModalForm
              show={openModal}
              title="Venta"
              handleModal={handleModalClose}
            >
              <SaleForm get_sales={get_sales} handleModal={handleModalClose} />
            </ModalForm>
          )}

          {openModalView && (
            <ModalForm
              show={openModalView}
              title="Ver fotos"
              handleModal={handleModalCloseView}
            >
              <SaleView
                editTool={editTool}
                handleModal={handleModalCloseView}
              />
            </ModalForm>
          )}
        </Col>
      </Row>
    </Container>
  );
}
