import React from "react";
import { Button } from "react-bootstrap";

export default function EventItem({
  id,
  tipo,
  localidad,
  fecha,
  concreto_venta,
  numero_asistentes,
  status,
  onDelete,
  onEdit,
  onView,
}) {
  return (
    <tr key={id}>
      <td>
        {" "}
        <span> {fecha} </span>
      </td>
      <td>
        <span>{tipo}</span>
      </td>
      <td>
        <span>{localidad}</span>
      </td>
      <td>
        <span>{concreto_venta}</span>
      </td>
      <td>
        <span>{concreto_venta}</span>
      </td>
      <td>
        <span>{numero_asistentes}</span>
      </td>
      <td>
        {status === "Pendiente" && (
          <span class="badge rounded-pill bg-warning text-dark">{status}</span>
        )}
        {status === "Autorizado" && (
          <span class="badge rounded-pill bg-success">{status}</span>
        )}
        {status === "Rechazado" && (
          <span class="badge rounded-pill bg-danger">{status}</span>
        )}
      </td>
      {onView && (
        <td>
          <Button variant="warning" onClick={() => onView(id)}>
            Ver
          </Button>
        </td>
      )}
      {onDelete && (
        <td>
          <span>
            <Button variant="danger" onClick={() => onDelete(id)}>
              {" "}
              Eliminar{" "}
            </Button>
          </span>
        </td>
      )}
    </tr>
  );
}
