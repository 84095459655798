import React, { useState, useEffect } from 'react';
import ReactDOM from "react-dom";
import { Modal, Button } from "react-bootstrap";
import useDilers from '../../hooks/useDilers';
import eliminar from "../../assets/delete.gif";

function ModalDelete({
    show,
    title,
    children,
    handleModalDelClose,
    Nombre,
    Numero,
    Id,
    token,
    DistribuidoresGetService }) {

    const { loading, error, message, success, DistribuidoresDeleteService } = useDilers();

    const ElimiarCuenta = () => {
        const data = new FormData();
        data.append("id", Id);

        DistribuidoresDeleteService(data, token);
    }

    useEffect(() => {
        if (success == true) {
            DistribuidoresGetService(token)
            handleModalDelClose(false)
        }
    }, [DistribuidoresGetService, success])

    console.log(!show);

    return ReactDOM.createPortal(
        <Modal
            show={show}
            onHide={() => handleModalDelClose(false)}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <img style={{ width: 90 }} className="d-block mx-auto" src={eliminar} />
                        <h3>Esta seguro de eliminar el sitribuidor "{Nombre}"</h3>
                        <p>Esta operación es irreversible</p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => handleModalDelClose(!show)}>
                    Cerrar
                </Button>
                <Button variant="danger" onClick={(() => ElimiarCuenta())}>Eliminar</Button>
            </Modal.Footer>
        </Modal>,
        document.getElementById("modal")
    );
}

export { ModalDelete };


// export default function ModalDelete({
//     ModalDel,
//     toggleDel,
//     Nombre,
//     Numero,
//     Id,
//     token,
//     DistribuidoresGetService }) {

//     const { loading, error, message, success, DistribuidoresDeleteService } = useDilers();

//     useEffect(() => {
//         if (success == true) {
//             DistribuidoresGetService(token)
//             toggleDel();
//         }
//     }, [DistribuidoresGetService, success])

//     const ElimiarCuenta = () => {
//         const data = new FormData();
//         data.append("id", Id);

//         DistribuidoresDeleteService(data, token);
//     }


//     return <>
//         <Modal isOpen={ModalDel} toggle={toggleDel}>
//             <ModalHeader className="backend_titulo" toggle={toggleDel}>Eliminar Distribuidor [ {Numero} ]</ModalHeader>
//             <ModalBody>
//                 <div className="row">
//                     <div className="col-md-12 text-center">
//                         <img style={{ width: 90 }} className="d-block mx-auto" src={eliminar} />
//                         <h3>Esta seguro de eliminar el sitribuidor "{Nombre}"</h3>
//                         <p>Esta operación es irreversible</p>
//                     </div>
//                 </div>
//             </ModalBody>
//             <ModalFooter>
//                 <Button color="secondary" onClick={(() => ElimiarCuenta())}>Eliminar</Button>{' '}
//                 <Button color="danger" onClick={toggleDel}>Cancel</Button>{' '}
//             </ModalFooter>
//         </Modal>
//     </>
// }
