import React from 'react'
import SaleList from '../components/Sale/SaleList' 

export default function Sales() {
  return (
    <React.Fragment>
      <SaleList />
    </React.Fragment>
  )
}
