/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from "react";
import Context from "../../context/AppContext";
import api from "../../services/events";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useForm, Controller } from "react-hook-form";
import {
  Container,
  Form,
  Row,
  Col,
  Button,
  FloatingLabel,
} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { es } from "date-fns/locale";
import File from "../Form/File";
import format from "date-fns/format";
import Swal from "sweetalert2";
import Compressor from "compressorjs";
import pic from "../../assets/loader.gif";

const animatedComponents = makeAnimated();

export default function EventForm({ handleModal, get_events }) {
  const [products, setProducts] = useState([]);
  const [registered, setRegistered] = useState(false);
  //const [visible_monto, setvisible_monto] = useState(false);
  const [files, setfiles] = useState([]);
  const { userData, token } = useContext(Context);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm();

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (value.tipo === "Gestion Punto de Venta") {
        setRegistered(true);
      } else {
        setRegistered(false);
      }

      /*       if (value.concreto_venta === "Si") {
        setvisible_monto(true);
      } else {
        setvisible_monto(false);
      } */
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = (data, e) => {
    e.preventDefault();
    switch (registered) {
      case true:
        save_gestion(data);
        break;
      case false:
        save_normal(data);
        break;
      default:
        break;
    }
  };

  function compress(file) {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.5,
        success: resolve,
        error: reject,
      });
    });
  }

  const save_normal = async (data) => {
    if (files.length === 3) {
      const sweet_loader = "<img src=" + pic + " class='img-fluid' />";
      Swal.fire({
        title: "Enviando información",
        html: sweet_loader,
        buttons: false,
        showConfirmButton: false,
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });

      const usuario =
        typeof userData === "string" ? JSON.parse(userData) : userData;
      data.fecha = format(data.fecha, "yyyy-MM-dd HH:mm:ss");

      const formData = new FormData();
      formData.append("tipo", data.tipo);
      formData.append("fecha", data.fecha);
      formData.append("numero_asistentes", data.numero_asistentes);
      //formData.append("concreto_venta", data.concreto_venta);
      //formData.append("monto", data.concreto_venta === "Si" ? data.monto : 0);
      formData.append("localidad", data.localidad);
      formData.append("descripcion", data.descripcion);
      formData.append("user_id", usuario.id);
      formData.append("size", files.length);

      for (let i = 0; i < files.length; i++) {
        const valor = await compress(files[i].file);
        formData.append(`imagenes_${i}`, valor);
      }
      api.eventsStore(token, formData).then((response) => {
        if (response.success) {
          get_events();
          handleModal(false);
          Swal.fire(
            "Guardado con exito",
            "Registro creado de manera exitosa",
            "success"
          );
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Hubo un error",
          });
        }
      });
    } else {
      Swal.fire("Error", "Debe de agregar 3 fotos", "error");
    }
  };

  const save_gestion = (data) => {
    if (files.length === 3) {
      const sweet_loader = "<img src=" + pic + " class='img-fluid' />";
      Swal.fire({
        title: "Enviando información",
        html: sweet_loader,
        buttons: false,
        showConfirmButton: false,
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });

      const usuario =
        typeof userData === "string" ? JSON.parse(userData) : userData;
      data.fecha_realizacion = format(
        data.fecha_realizacion,
        "yyyy-MM-dd HH:mm:ss"
      );

      const formData = new FormData();
      formData.append("tipo", data.tipo);
      formData.append("fecha", data.fecha_realizacion);
      formData.append("descripcion", data.descripcion);
      formData.append("user_id", usuario.id);
      formData.append("size", files.length);
      for (let i = 0; i < files.length; i++) {
        formData.append(`imagenes_${i}`, files[i].file);
      }

      api.eventStoreGestion(token, formData).then((response) => {
        if (response.success) {
          get_events();
          handleModal(false);
          Swal.fire(
            "Guardado con exito",
            "Registro creado de manera exitosa",
            "success"
          );
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Hubo un error",
          });
        }
      });
    } else {
      Swal.fire("Error", "Debe de agregar 3 fotos", "error");
    }
  };

  return (
    <React.Fragment>
      <Container fluid="xxl" className="registro">
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Row className="justify-content-center mt-5">
            <Col xs={12} md={registered ? 6 : 4}>
              <Form.Group className="mb-3" controlId="tipo">
                <Form.Label>Tipo de Evento</Form.Label>

                <Form.Select
                  aria-label="tipo de evento"
                  {...register("tipo", {
                    required: "El campo es obligatorio.",
                  })}
                >
                  <option value="">Selecciona una opción</option>
                  <option value="Dia de tienda Externo">
                    Dia de tienda Externo{" "}
                  </option>
                  <option value="Demostración de equipos">
                    Demostración de equipos
                  </option>
                  <option value="Demostración venta masiva">
                    Demostración venta masiva
                  </option>
                  <option value="Dia de tienda">Dia de tienda</option>
                  <option value="Gestion Punto de Venta">
                    Gestion Punto de Venta
                  </option>
                </Form.Select>
                <Form.Text className="text-muted">
                  {errors.tipo?.message}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xs={12} md={registered ? 6 : 4}>
              <Form.Group className="mb-3" controlId="fecha">
                <Form.Label>
                  {registered ? "Fecha de realización" : "Fecha"}
                </Form.Label>
                <Controller
                  rules={{ required: "El campo es obligatorio" }}
                  control={control}
                  name={registered ? "fecha_realizacion" : "fecha"}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Form.Control
                      inputRef={ref}
                      type="text"
                      autoComplete="off"
                      as={ReactDatePicker}
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      maxDate={new Date()}
                      locale={es}
                      placeholderText="Selecciona la fecha"
                      dateFormat="dd-MM-yyyy"
                      error={!!errors.fecha}
                    />
                  )}
                />

                <Form.Text className="text-muted">
                  {registered
                    ? errors.fecha_realizacion?.message
                    : errors.fecha?.message}
                </Form.Text>
              </Form.Group>
            </Col>
            {!registered && (
              <Col xs={12} md={4}>
                <Form.Group className="mb-3" controlId="numero_asistentes">
                  <Form.Label>Número de asistentes </Form.Label>
                  <Form.Control
                    type="text"
                    {...register("numero_asistentes", {
                      required: "El campo es obligatorio.",
                      pattern: {
                        value: /^[0-9]+$/i,
                        message: "Favor de escribir números enteros.",
                      },
                    })}
                  />
                  <Form.Text className="text-muted">
                    {errors.numero_asistentes?.message}
                  </Form.Text>
                </Form.Group>
              </Col>
            )}
          </Row>
          {!registered && (
            <Row>
              <Col xs={12} md={4}>
                <Form.Group className="mb-3" controlId="localidad">
                  <Form.Label>Localidad</Form.Label>
                  <Form.Control
                    type="text"
                    {...register("localidad", {
                      required: "El campo es obligatorio.",
                    })}
                  />
                  <Form.Text className="text-muted">
                    {errors.localidad?.message}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="descripcion">
                <FloatingLabel
                  controlId="descripcion1"
                  label={
                    registered ? "Descripción de la actividad" : "Descripción"
                  }
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Escribe una breve descripción"
                    style={{ height: "100px" }}
                    {...register("descripcion", {
                      required: "El campo es obligatorio.",
                    })}
                  />
                </FloatingLabel>

                <Form.Text className="text-muted">
                  {errors.descripcion?.message}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <File
                name="files"
                title="Fotos (3)"
                files={files}
                setfiles={setfiles}
                limit={3}
                fileTypes={["image/png", "image/jpeg"]}
              />
            </Col>
          </Row>

          <Button type="submit" className="mb-4 mt-2">
            Enviar
          </Button>
        </Form>
      </Container>
    </React.Fragment>
  );
}
