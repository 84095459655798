import React, { useState, useEffect } from 'react';
import ReactDOM from "react-dom";
import { Modal, Button } from "react-bootstrap";
import useDilers from '../../hooks/useDilers';

function ModalDistribuidores({
    show,
    title,
    children,
    handleModal,
    Nombre,
    Numero,
    Id,
    token,
    Update,
    setNombre,
    setNumero,
    DistribuidoresGetService }) {

    const { success, error, DistribuidoresAddService, DistribuidoresUpdateService } = useDilers();

    useEffect(() => {
        if (success === true) {
            DistribuidoresGetService(token)
        }
    }, [DistribuidoresGetService, success])

    const createUser = () => {
        if (Update) {
            const data = new FormData();
            data.append("id", Id);
            data.append("nombre", Nombre);
            data.append("numero", Numero);

            DistribuidoresUpdateService(data, token);
        } else {
            const data = new FormData();
            data.append("nombre", Nombre);
            data.append("numero", Numero);

            DistribuidoresAddService(data, token);
        }
    }

    useEffect(() => {
        if (success == true) {
            DistribuidoresGetService(token)
            handleModal(false)
        }
    }, [DistribuidoresGetService, success])

    return ReactDOM.createPortal(
        <Modal
            show={show}
            onHide={() => handleModal(false)}
            backdrop="static"
            keyboard={false}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12">
                        <input placeholder="Nombre" className='form-control' value={Nombre} onChange={e => setNombre(e.target.value)} />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-12">
                        <input placeholder="Numero" className='form-control' value={Numero} onChange={e => setNumero(e.target.value)} />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => handleModal(false)}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={(() => createUser())}>Guardar</Button>
            </Modal.Footer>
        </Modal>,
        document.getElementById("modal")
    );
}

export { ModalDistribuidores };