const ENDPOINT = process.env.REACT_APP_API_URL;

async function sales (token) {
  return await fetch(`${ENDPOINT}/sales`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token
    }
  }).then(res => {
    if (!res.ok) throw new Error('Response is NOT ok')
    return res.json()
  })
}

async function saleDestroy( token, id ) {
  return await fetch(`${ENDPOINT}/sales/${id}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token
    },
  }).then(res => {
    if (!res.ok) throw new Error('Response is NOT ok')
    return res.json()
  });  
}

async function saleStore( token, event) {
  return await fetch(`${ENDPOINT}/sales`, {
    method: 'POST',
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: 'Bearer ' + token,
    },
    body: event
  }).then(res => {
    if (!res.ok) throw new Error('Response is NOT ok')
    return res.json()
  });  
}

async function saleUpdate( token, id, data) {
  return await fetch(`${ENDPOINT}/sales/${id}`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: JSON.stringify({ event: data })
  }).then(res => {
    if (!res.ok) throw new Error('Response is NOT ok')
    return res.json()
  });  
}

async function events_picklist(token) {
  return await fetch(`${ENDPOINT}/events-picklist`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token
    }
  }).then(res => {
    if (!res.ok) throw new Error('Response is NOT ok')
    return res.json()
  })
}

export default { sales, saleDestroy, saleStore, saleUpdate, events_picklist } ;
