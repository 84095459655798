const ENDPOINT = process.env.REACT_APP_API_URL;

async function info_user (token) {
  return await fetch(`${ENDPOINT}/info-user`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token
    }
  }).then(res => {
    if (!res.ok) throw new Error('Response is NOT ok')
    return res.json()
  })
}


async function users (token) {
  return await fetch(`${ENDPOINT}/users`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token
    }
  }).then(res => {
    if (!res.ok) throw new Error('Response is NOT ok')
    return res.json()
  })
}

async function usersDestroy( token, id ) {
  return await fetch(`${ENDPOINT}/users/${id}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token
    },
  }).then(res => {
    if (!res.ok) throw new Error('Response is NOT ok')
    return res.json()
  });  
}

async function usersStore( token, user) {
  return await fetch(`${ENDPOINT}/users`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user)
  }).then(res => {
    if (res.status==422) return res.json();
    if (!res.ok) throw new Error('Response is NOT ok')
    return res.json()
  });  
}

async function userUpdate( token, id, data) {
  return await fetch(`${ENDPOINT}/users/${id}`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: JSON.stringify({ user: data })
  }).then(res => {
    if (!res.ok) throw new Error('Response is NOT ok')
    return res.json()
  });  
}

export default { users, usersDestroy, usersStore, userUpdate, info_user } ;
