import React, { useContext } from "react";
import { Card, Button } from "react-bootstrap";
import { FaFileExcel } from "react-icons/fa";
import report_api from "../services/reports";
import Context from "../context/AppContext";
import fileDownload from "js-file-download";
import { useNavigate } from "react-router-dom";

const array = [
  { message: "Descargar Reportes", button: "Descargar" },
  { message: "Autorizar eventos", button: "Ir" },
];
export default function RerportesExcel() {
  const navigate = useNavigate();
  const { token } = useContext(Context);

  const descargar = () => {
    report_api.reports_descargar(token).then((response) => {
      fileDownload(response, "reporte.xlsx");
    });
  };

  const gotoAutorizar = () => {
    navigate("/events-autorize");
  };

  return (
    <div className="row mt-5">
      {array.map(({ button, message }) => {
        return (
          <div key={message} className="col-md-4">
            <Card style={{ width: "20rem" }}>
              <Card.Body>
                <div className="d-flex justify-content-center">
                  <FaFileExcel color="#ED7203" size={128} />
                </div>
                <Card.Title className="text-center mt-4">{message}</Card.Title>
                <div className="d-grid gap-2 mt-3">
                  <Button
                    onClick={button === "Ir" ? gotoAutorizar : descargar}
                    type={button === "Ir" ? "button" : "submit"}
                    className="mb-4 mt-2"
                  >
                    {button}
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>
        );
      })}
    </div>
  );
}
