/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaCalendar, FaSellcast, FaFileExcel } from "react-icons/fa";
import Context from "../context/AppContext";
import { useNavigate } from "react-router-dom";

const array = [
  { message: "Ingresar eventos", url: "/events" },
  { message: "Ingresa venta realizada", url: "/sales" },
  { message: "Consultar eventos realizados", url: "/reports" },
];
export default function Dashboard() {
  let navigate = useNavigate();
  const { userData } = useContext(Context);

  useEffect(() => {
    const usuario =
      typeof userData === "string" ? JSON.parse(userData) : userData;
    if (usuario.rol === "Admin") {
      navigate("/reports-descargar");
    }
    return () => {};
  }, []);

  return (
    <div className="row row-col-3 mt-5">
      {array.map(({ message, url }) => {
        return (
          <div key={url} className="col">
            <Card style={{ width: "20rem" }}>
              <Card.Body>
                <div className="d-flex justify-content-center">
                  {url === "/events" ? (
                    <FaCalendar color="#ED7203" size={128} />
                  ) : url === "/sales" ? (
                    <FaSellcast color="#ED7203" size={128} />
                  ) : (
                    <FaFileExcel color="#ED7203" size={128} />
                  )}
                </div>
                <Card.Title className="text-center mt-4">{message}</Card.Title>
                <div className="d-grid gap-2 mt-3">
                  <Link className="btn btn-primary btn-block" to={url}>
                    Ir
                  </Link>
                </div>
              </Card.Body>
            </Card>
          </div>
        );
      })}
    </div>
  );
}
