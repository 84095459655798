import React from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { Form } from "react-bootstrap";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview
);

function App(props) {
  const { files, setfiles, title, limit, fileTypes } = props;
  return (
    <Form.Group className="mb-3" controlId={title}>
      <Form.Label>{title}</Form.Label>
      <FilePond
        acceptedFileTypes={fileTypes}
        files={files}
        onupdatefiles={setfiles}
        allowMultiple={true}
        maxFiles={limit}
        labelIdle='Arrastra y suelta tu archivo o <span class="filepond--label-action">Sube</span>'
      />
    </Form.Group>
  );
}

export default App;
