const ENDPOINT = process.env.REACT_APP_API_URL;

export default function ZonesService (token) {
  return fetch(`${ENDPOINT}/zones`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token
    }
  }).then(res => {
    if (!res.ok) throw new Error('Response is NOT ok')
    return res.json()
  })
}