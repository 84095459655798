import React from "react";
import { Button } from "react-bootstrap";

export default function EventItem(props) {
  const {
    id,
    tipo,
    localidad,
    fecha,
    concreto_venta,
    numero_asistentes,
    status,
    comment,
    onEdit,
    onView,
  } = props;
  return (
    <tr key={id}>
      <td>
        {" "}
        <span> {fecha} </span>
      </td>
      <td>
        <span>{tipo}</span>
      </td>
      <td>
        <span>{localidad}</span>
      </td>
      <td>
        <span>{concreto_venta}</span>
      </td>
      <td>
        <span>{concreto_venta}</span>
      </td>
      <td>
        <span>{numero_asistentes}</span>
      </td>
      <td>
        {status === "Pendiente" && (
          <span class="badge rounded-pill bg-warning text-dark">{status}</span>
        )}
        {status === "Autorizado" && (
          <span class="badge rounded-pill bg-success">{status}</span>
        )}
        {status === "Rechazado" && (
          <>
            <span class="badge rounded-pill bg-danger">{status}</span>
            <p>{comment}</p>
          </>
        )}
      </td>
      {onView && (
        <td>
          <Button variant="warning" onClick={() => onView(id)}>
            Ver
          </Button>
        </td>
      )}
      {onEdit && (
        <td>
          <span>
            <Button variant="info" onClick={() => onEdit(id)}>
              Autorizar evento{" "}
            </Button>
          </span>
        </td>
      )}
    </tr>
  );
}
