import React, { useContext } from "react";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  NavItem,
  NavLink,
} from "react-bootstrap";
import { useNavigate, useMatch } from "react-router-dom";
import useUser from "../hooks/useUser";
import pic from "../assets/images/logo_especialista_marca.png";
import Context from "../context/AppContext";

export default function Header() {
  const { userData } = useContext(Context);
  const usuario =
    typeof userData === "string" ? JSON.parse(userData) : userData;
  const match = useMatch("/");
  const navigate = useNavigate();
  const { isLogged, logout } = useUser();
  const goToRoute = (ruta) => {
    navigate("/" + ruta);
  };
  const handleClick = (e) => {
    e.preventDefault();
    logout();
    navigate("/", { replace: true });
  };

  return (
    <Navbar bg="light" expand="md">
      <Container>
        <Navbar.Brand href="/">
          <img className="img-fluid" src={pic} style={{ width: "120px" }} alt="Logo" />{" "}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {match == null && (
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {usuario.rol === "Promotor" ? (
                <React.Fragment>
                  <NavItem>
                    <NavLink onClick={() => goToRoute("dashboard")}>
                      Inicio
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink onClick={() => goToRoute("events")}>
                      Eventos
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink onClick={() => goToRoute("sales")}>Ventas</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink onClick={() => goToRoute("reports")}>
                      Reportes
                    </NavLink>
                  </NavItem>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <NavItem>
                    <NavLink onClick={() => goToRoute("events-autorize")}>
                      Autorizar evento
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink onClick={() => goToRoute("reports-descargar")}>
                      Reportes
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink onClick={() => goToRoute("users")}>
                      Usuarios
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink onClick={() => goToRoute("dilers")}>
                      Distribuidores
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink onClick={() => goToRoute("demos")}>
                      Equipos Demo
                    </NavLink>
                  </NavItem>
                </React.Fragment>
              )}

              <NavDropdown
                title={usuario ? usuario.nombre : "usuario"}
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item onClick={() => goToRoute("mis-datos")}>Mis Datos</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavItem>
                  <NavLink onClick={handleClick}>Cerrar sesion</NavLink>
                </NavItem>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        )}
      </Container>
    </Navbar>
  );
}
