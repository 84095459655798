import { useCallback, useContext, useState } from "react";
import Context from "../context/AppContext";
import loginService from "../services/login";

export default function useUser() {
  const { token, setToken, IDUser, setIDUser, userData, setUserData } = useContext(Context);
  const [state, setState] = useState({ loading: false, error: false });

  const login = useCallback(
    ({ username, password }) => {
      setState({ loading: true, error: false });
      loginService({ username, password })
        .then(({ token, id_user, user_data }) => {
          window.sessionStorage.setItem("token", token);
          window.sessionStorage.setItem("id_user", id_user);
          window.sessionStorage.setItem("user_data", JSON.stringify(user_data));
          setState({ loading: false, error: false });
          setToken(token);
          setIDUser(id_user);
          setUserData(user_data);
        })
        .catch((err) => {
          window.sessionStorage.removeItem("token");
          window.sessionStorage.removeItem("id_user");
          window.sessionStorage.removeItem("user_data");
          setState({ loading: false, error: true });
          console.error(err);
        });
    },
    [setToken, setIDUser]
  );

  const logout = useCallback(() => {
    window.sessionStorage.removeItem("token");
    window.sessionStorage.removeItem("id_user");
    window.sessionStorage.removeItem("user_data");
    setToken(null);
    setIDUser(null);
  }, [setToken, setIDUser]);

  return {
    tokenFull: "Bearer " + token,
    isLogged: Boolean(token),
    isLoginLoading: state.loading,
    hasLoginError: state.error,
    login,
    logout,
  };
}
