/* eslint-disable import/no-anonymous-default-export */
const ENDPOINT = process.env.REACT_APP_API_URL;

async function events(token) {
  return await fetch(`${ENDPOINT}/events`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

async function eventsDestroy(token, id) {
  return await fetch(`${ENDPOINT}/events/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

async function eventsStore(token, event) {
  return await fetch(`${ENDPOINT}/events`, {
    method: "POST",
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token,
    },
    body: event,
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

async function eventStoreGestion(token, event) {
  return await fetch(`${ENDPOINT}/events-gestion`, {
    method: "POST",
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token,
    },
    body: event,
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

async function eventsUpdate(token, id, data) {
  return await fetch(`${ENDPOINT}/events/${id}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify({ event: data }),
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

async function eventsSale(token) {
  return await fetch(`${ENDPOINT}/events-sales`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

export default {
  events,
  eventsDestroy,
  eventsStore,
  eventsUpdate,
  eventStoreGestion,
  eventsSale
};
