import React, { useEffect, useContext, useState } from "react";

import api from "../services/demos";
import Context from "./AppContext";
import Swal from "sweetalert2";

const DemoContext = React.createContext();

function DemoProvider(props) {
  const [demos, setDemos] = useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const { token } = useContext(Context);

  useEffect(() => {
    api.demos(token).then((data) => {
      setDemos(data);
    });
  }, [token]);

  const deleteDemo = (id) => {
    Swal.fire({
      title: "¿Estás seguro de eliminar a un distribuidor?",
      showDenyButton: true,
      showCancelButton: true,
      showConfirmButton: false,
      denyButtonText: `Eliminar`,
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isDenied) {
        api.DemosDestroy(token, id).then((data) => {
          const todoIndex = demos.findIndex((demo) => demo.id === id);
          const newDemos = [...demos];
          newDemos.splice(todoIndex, 1);
          setDemos(newDemos);
        });
      }
    });
  };

  const saveDemo = (data) => {
    const {
      picklist_dealers: { value },
      picklist_products,
    } = data;
    const formData = new FormData();
    formData.append("dealer", value);
    picklist_products.forEach((model) =>
      formData.append("products[]", model.value)
    );
    api.demosStore(token, formData).then(({ dealerNew }) => {
      const newDemos = [...demos];
      newDemos.push(dealerNew);
      setDemos(newDemos);

      setOpenModal(false);
    });
  };

  const updateDemo = (data) => {
    const {
      picklist_dealers: { value },
      picklist_products,
    } = data;

    const products = picklist_products.map((item) => item.value);
    const payload = JSON.stringify({ dealer: value, products: products });

    api.demosUpdate(token, payload, value).then(({ dataUpdated }) => {
      setDemos(dataUpdated);
      setOpenModal(false);
    });
  };

  const mappingSelect = (producs) => {
    const mapping = producs.map((item, index) => {
      return { key: index, label: item.model, value: item.id };
    });
    return mapping;
  };
  const mappingDealerSelected = (dealer) => {
    return {
      label: ` ${dealer.customer_number} ${dealer.nombre_comercial} `,
      value: dealer.id,
    };
  };

  return (
    <DemoContext.Provider
      value={{
        demos,
        deleteDemo,
        saveDemo,
        openModal,
        setOpenModal,
        mappingSelect,
        updateDemo,
        mappingDealerSelected,
      }}
    >
      {props.children}
    </DemoContext.Provider>
  );
}

export { DemoContext, DemoProvider };
